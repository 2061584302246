var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"channel g-df-vc wrap"},[_vm._l((_vm.getList),function(item){return [(_vm.channels[item.type])?_c('li',{key:item.type,staticClass:"g-df-vc g-bs-bb g-por",class:{
        'g-cursor-pointer': item.quantity > 0 && item.type !== 'dtk'
      }},[(item.is_rank === 1)?_c('img',{staticClass:"g-poa rank",attrs:{"src":require("@viewsImg/promote-record/product-rank.png"),"alt":""}}):_vm._e(),_c('img',{staticClass:"g-por img",attrs:{"src":_vm.channels[item.type].src,"alt":""}}),_c('section',{staticClass:"right-wrap g-text-ellipsis"},[(item.seller_name)?_c('Help',{attrs:{"msg":item.seller_name === '--'
              ? ''
              : item.seller_name + _vm.getRightSymbol(item)}},[_c('a',{staticClass:"title g-color-primary g-text-ellipsis",class:{ 'cur-no': item.type === 'dtk' },attrs:{"slot":"icon","href":item.type !== 'dtk' &&
                (item.url || _vm.getChannelQuantityUrl(item.type)),"target":"_blank"},slot:"icon"},[_vm._v(" "+_vm._s(item.seller_name + _vm.getRightSymbol(item))+" ")])]):(item.quantity >= 0)?_c('p',{staticClass:"title g-color-primary g-text-underline g-cursor-pointer",on:{"click":function($event){_vm.channel(item, {
              timeOfDay: _vm.getDate()
            })}}},[_vm._v(" "+_vm._s(_vm._f("tenThousandW")(item.quantity))+" ")]):_vm._e(),_c('p',{staticClass:"desc"},[_vm._v(_vm._s(_vm.channels[item.type].title))])],1)]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }