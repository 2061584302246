import axios from 'axios'
import factory from './factory'

const proxy = process.env.VUE_APP_API_URL_PROXY_HDK || ''
const instance = axios.create({
  baseURL: proxy ? '' : process.env.VUE_APP_API_URL_HDK
})
const { requestByGet /* , requestByGet, requestFile, request */ } = factory({
  instance,
  proxy
})

export { requestByGet }
