import { requestByGet } from '@/http'
import { requestByGet as requestByGetHDK } from '@/http/hdk'

const prefix = '/promotion/'
const getApiName = (name: string): string => prefix + name

export const getPromoteProduct = (data?: {}) => {
  return requestByGet(getApiName('info'), data)
}

// export const getPromoteProductForSaleRank = (data?: {}) => {
//   return requestByGet(getApiName('saledetails'), data)
// }

export const getPromoteHistory = (data?: {}, other?: {}) => {
  return requestByGet(getApiName('history'), data, other)
}

// export const getPromoteHistoryForSaleRank = (data?: {}, other?: {}) => {
//   return requestByGet(getApiName('salehistory'), data, other)
// }

export const getPromoteGuides = (data?: {}, other?: {}) => {
  return requestByGet(getApiName('material'), data, other)
}

export const getPromoteGuidesByHdk = (data?: {}, other?: {}) => {
  return requestByGetHDK('/duotool/items_old', data, other)
}

export const getPromoteHistoryByDay = (data?: {}, other?: {}) => {
  return requestByGet(getApiName('daydata'), data, other)
}

export const getPromoteHistoryByHour = (data?: {}, other?: {}) => {
  return requestByGet(getApiName('hourdata'), data, other)
}

export const getPromoteHistoryStat = (data?: {}, other?: {}) => {
  return requestByGet(getApiName('historyStat'), data, other)
}
