import { Vue, Component, Inject } from 'vue-property-decorator'

@Component
export default class MixinInjectProduct extends Vue {
  @Inject() provide!: {
    product: null | {}
  }

  get product() {
    return this.provide.product || {}
  }
}
