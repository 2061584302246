import { Vue, Component, Provide } from 'vue-property-decorator'

@Component
export default class MixinProvideProduct extends Vue {
  @Provide() provide = {
    product: null as null | {}
  }

  get product() {
    return this.provide.product
  }
}
